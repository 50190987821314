import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Test from "./Router";
import "./index.css";
import "./App.css";

const App = () => {
  return (
    <div className="">
      <Test />
    </div>
  );
};
export default App;
